const DEFAULT_DATA = {
	isBundle     : false,
	isBundledItem: false,
	bundleData   : {},
	itemData     : {}
}

export const getData = extensions => ( {
	...DEFAULT_DATA,
	...( extensions?.[ 'yith-woocommerce-product-bundles' ] ?? {} )
} );

export const getBundleData = extensions => {
	const { isBundle, isBundledItem, bundleData } = getData( extensions );
	return isBundle || isBundledItem ? bundleData : {};
};

export const getItemData = extensions => {
	const { isBundledItem, itemData } = getData( extensions );
	return isBundledItem ? itemData : {};
};

export const isLastVisibleBundledItem = ( cartItem, cart ) => {
	const { cartItems }                    = cart;
	const { extensions, key: cartItemKey } = cartItem;
	const { isBundledItem = false }        = getData( extensions );

	if ( !isBundledItem ) {
		return false;
	}

	const { bundledItemKeys = [] } = getBundleData( extensions );

	const bundledItems           = bundledItemKeys.map( key => cartItems.find( _ => _.key === key ) ).filter( Boolean );
	const visibleBundledItems    = bundledItems.filter( item => {
		const { isHidden = false } = getItemData( item?.extensions );
		return !isHidden;
	} );
	const visibleBundledItemKeys = visibleBundledItems.map( _ => _.key );

	return visibleBundledItemKeys.pop() === cartItemKey;
}