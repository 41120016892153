import { registerCheckoutFilters }                                       from '@woocommerce/blocks-checkout';
import { getBundleData, getData, getItemData, isLastVisibleBundledItem } from './utils';

registerCheckoutFilters( 'yith-woocommerce-product-bundles', {
	cartItemClass     : ( value, extensions, arg ) => {
		const { isBundle = false, isBundledItem = false }     = getData( extensions );
		const { hasFixedPrice = false }                       = getBundleData( extensions );
		const { isHidden = false, isThumbnailHidden = false } = getItemData( extensions );
		const { cart, cartItem }                              = arg;

		return [
			value,
			!!isBundle ? 'yith-wcpb-is-bundle' : '',
			!!isBundledItem ? 'yith-wcpb-is-bundled-item' : '',
			!!hasFixedPrice ? 'yith-wcpb-has-fixed-price' : '',
			!!isHidden ? 'yith-wcpb-is-hidden' : '',
			!!isThumbnailHidden ? 'yith-wcpb-is-thumbnail-hidden' : '',
			!!isLastVisibleBundledItem( cartItem, cart ) ? 'yith-wcpb-is-last-visible-bundled-item' : ''
		].filter( Boolean ).join( ' ' );
	},
	showRemoveItemLink: ( value, extensions ) => {
		const { isBundledItem = false } = getData( extensions );
		return isBundledItem ? false : value;
	},
	itemName          : ( value, extensions ) => {
		const { hasCustomName = false, name = '' } = getItemData( extensions );
		return hasCustomName ? name : value;
	}
} );